import {
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import axios from 'axios'
import qs from 'qs'

import { shopifyClient } from 'api/shop'
import {
  Event,
  EventForParticipation,
  EventParticipant,
  PartialEvent,
  UpdateEvent,
  DraftProps,
  DraftType,
  ApplyEvent,
  UpdateParticipantStatus,
  CreateParentProps,
  SponsorProps,
  EventForParticipationParams,
} from './types/Event.types'
import { MainPageHeaderContent, MediaItem } from './types/MainPageHeaderContent'
import { Ambassador, Child, ChildUpdateBody } from './types/Child.types'
import {
  AuthProfile,
  MyProfile,
  ResetPinBody,
  UserAccount,
  UserProfile,
} from './types/Auth.types'
import { NewSponsorProps } from '../../scenes/Events/Sponsors/SponsorModal'
import { getAuthUserToken } from '../../services/authentication'
import {
  PublicRecordParams,
  ChildSubmissionResponse,
  Record,
  RecordPriority,
  RecordSubmissionParams,
  Submission,
  SubmissionResponse,
  SubmissionUpdateBody,
  SubmissionFilters,
  SubmissionCreateBody,
  GroupRecordUpdateBody,
  WorldMapRecordsAmount,
  CmsData,
  CmsDataToUpdate,
} from './types/Record.types'
import { ReviewRecord } from './types/Record.types'
import {
  CreateAttemptProps,
  QuizAttempt,
  Quiz,
  AnswerResult,
  AnswerBody,
  CompleteBody,
  QuizSuggestion,
  RecordHolders,
  QuizRecordScore,
  QuizRecord,
} from './types/Quiz.types'
import { ContactUsBody } from './types/Email.types'
import * as localStorage from '../../utils/localStorage'
import {
  AllUsersFilters,
  ParentResponse,
  ParentUpdateBody,
} from './types/Parent.types'
import { Location } from 'data/Location'
import { IPLocationResponse } from './types/Location.types'
import { ChildRecords, Product } from './types/Product.types'
import { transformProductData } from './api.utils'
import { ChildProfile } from '../../data/ChildProfile'
import {
  Recommendation,
  RecommendationUpdateBody,
} from './types/Recommendation.types'
import { ExportRecordsFormProps } from 'components/ShadowModal/ExportRecordsModal.utils'
import {
  AmbassadorFulfill,
  AmbassadorsResponse,
} from './types/Ambassadors.types'
import { PaymentForCountry } from './types/PaymentForCountries.types'

const baseQuery = fetchBaseQuery({
  baseUrl: '/api',
  prepareHeaders: (headers, { getState }) => {
    const token = getAuthUserToken()

    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }

    return headers
  },
})

// Define a service using a base URL and expected endpoints
export const kwrApi = createApi({
  reducerPath: 'kwrApi',
  baseQuery,
  tagTypes: [
    'Event',
    'EventParticipants',
    'Child',
    'Sponsor',
    'AuthProfile',
    'Record',
    'MyProfile',
    'Ambassador',
    'QuizAttempt',
    'Quiz',
    'RecordHolders',
    'Submission',
    'HeadContentList',
    'Recommendation',
    'WorldMapData',
    'CmsData',
    'PaymentForCountry',
  ],
  endpoints: (builder) => ({
    createParent: builder.mutation<{ authToken: string }, CreateParentProps>({
      query: (body) => ({
        url: `parents`,
        method: 'POST',
        body,
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }): Promise<void> {
        const response = (await queryFulfilled) as unknown as any
        localStorage.set('AUTH_TOKEN', response.data.token)
        dispatch(kwrApi.util.invalidateTags(['MyProfile', 'AuthProfile']))
      },
    }),

    createChild: builder.mutation<void, any>({
      query: (body) => ({
        url: `parents/children`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['MyProfile'],
    }),

    createEvent: builder.mutation<void, UpdateEvent>({
      query: ({ id, ...body }) => ({
        url: `admin/events/${id}`,
        method: 'POST',
        body,
      }),
    }),
    sentCertificates: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `/admin/events/${id}/sendEmail`,
        method: 'POST',
      }),
      invalidatesTags: ['Event'],
    }),

    getDraft: builder.query<DraftProps, DraftType>({
      query: (type: DraftType) => `/draft?type=${type}`,
    }),
    getEventsNameList: builder.query<PartialEvent[], void>({
      query: () => `/admin/events/titleList`,
    }),
    getEventParticipants: builder.query<EventParticipant[], string>({
      query: (eventId: string) =>
        `/admin/events/participants?eventId=${eventId}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'EventParticipants' as const,
                id,
              })),
              'EventParticipants',
            ]
          : ['EventParticipants'],
    }),
    updateEventParticipantStatus: builder.mutation<
      string,
      UpdateParticipantStatus
    >({
      query: ({ id, eventId, ...body }) => ({
        url: `/admin/events/participants/${id}/review`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['EventParticipants', 'Event'],
    }),
    getEvents: builder.query<Event[], void>({
      query: () => `/admin/events`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Event' as const, id })),
              'Event',
            ]
          : ['Event'],
    }),
    getPublicEvents: builder.query<Event[], void>({
      query: () => `/events`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Event' as const, id })),
              'Event',
            ]
          : ['Event'],
    }),
    getEvent: builder.query<Event, string>({
      query: (id: string) => `/admin/events/${id}`,
      providesTags: ['Event'],
    }),
    updateEvent: builder.mutation<void, UpdateEvent>({
      query: ({ id, ...body }) => ({
        url: `/admin/events/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Event'],
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPost } = await queryFulfilled
          dispatch(
            kwrApi.util.updateQueryData('getEvent', id, (draft) => {
              Object.assign(draft, updatedPost)
            }),
          )
        } catch {}
      },
    }),
    updateEventPrivacy: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `/admin/events/${id}/privacy`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Event'],
    }),
    updateEventVisibility: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `/admin/events/${id}/visibility`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Event'],
    }),
    deleteEvent: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `/admin/events/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Event'],
    }),
    getEventForParticipation: builder.query<
      EventForParticipation,
      EventForParticipationParams
    >({
      query: ({ id, ...params }) => `events/${id}?${qs.stringify(params)}`,
    }),
    applyToEvent: builder.mutation<void, ApplyEvent>({
      query: ({ id, ...body }) => ({
        url: `/events/participant/${id}`,
        method: 'POST',
        body,
      }),
    }),
    getHeadContent: builder.query<MainPageHeaderContent<MediaItem>, void>({
      query: () => `/admin/headContent`,
      providesTags: ['HeadContentList'],
    }),
    updateHeadContent: builder.mutation<void, MainPageHeaderContent<MediaItem>>(
      {
        query: (body) => ({
          url: `/admin/headContent`,
          method: 'PUT',
          body,
        }),
        invalidatesTags: ['HeadContentList'],
      },
    ),

    getChild: builder.query<Child, string>({
      query: (id) => `/child/${id}`,
      providesTags: ['Child'],
    }),

    getSponsors: builder.query<SponsorProps[], void>({
      query: () => `/admin/sponsors`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Sponsor' as const, id })),
              'Sponsor',
            ]
          : ['Sponsor'],
    }),
    getPublicSponsors: builder.query<SponsorProps[], void>({
      query: () => `/sponsors`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Sponsor' as const, id })),
              'Sponsor',
            ]
          : ['Sponsor'],
    }),
    createSponsor: builder.mutation<SponsorProps, NewSponsorProps>({
      // @ts-ignore2
      queryFn: async (
        { file, ...body },
        _queryApi,
        _extraOptions,
        fetchWithBQ,
      ) => {
        try {
          const response = await fetchWithBQ(
            `media/uploadUrl?format=${file.type.split('/')[1]}`,
          )
          const urlToUpload = (response.data as { url: string }).url
          await axios.put(urlToUpload, file, {
            headers: { 'Content-Type': file.type },
          })
          const imageUrl = urlToUpload.split('?')[0] || ''

          const result = await fetchWithBQ({
            url: `admin/sponsors`,
            method: 'POST',
            body: { ...body, imageUrl: imageUrl },
          })

          return result.data
            ? { data: result.data as SponsorProps }
            : { error: result.error as FetchBaseQueryError }
        } catch (e) {
          return { error: e }
        }
      },
      invalidatesTags: ['Sponsor'],
    }),
    updateSponsor: builder.mutation<void, NewSponsorProps>({
      query: ({ id, ...body }) => ({
        url: `/admin/sponsors/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Sponsor'],
    }),
    deleteSponsor: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `/admin/sponsors/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Sponsor'],
    }),
    letsTalk: builder.mutation<void, string>({
      query: (email: string) => ({
        url: '/letsTalk',
        method: 'POST',
        body: email,
      }),
    }),

    getAuthProfile: builder.query<AuthProfile, void>({
      query: () => `/auth/profile`,
      providesTags: ['AuthProfile'],
    }),

    getPublicRecords: builder.query<Record[], PublicRecordParams>({
      query: (params: PublicRecordParams) => {
        return {
          url: `/records?${qs.stringify(params)}`,
        }
      },
      providesTags: ['Record'],
    }),

    getRecordById: builder.query<Record, string>({
      query: (id: string) => `/records/${id}`,
      providesTags: (result, error, arg) => [{ type: 'Record', id: arg }],
    }),
    createRecord: builder.mutation<Record, RecordSubmissionParams>({
      query: (body) => ({
        url: `/records`,
        method: 'POST',
        body,
      }),
    }),

    joinAmbassadorProgram: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `/child/${id}/ambassador`,
        method: 'PATCH',
      }),
      invalidatesTags: ['MyProfile'],
    }),
    getAmbassadoredRecords: builder.query<Record[], void>({
      query: () => '/ambassador/records',
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Record' as const, id })),
              'Record',
            ]
          : ['Record'],
    }),
    getAmbassador: builder.query<Ambassador, string>({
      query: (code: string) => `/ambassador/${code}`,
      providesTags: ['Ambassador'],
    }),

    getProfile: builder.query<MyProfile, void>({
      query: () => `/me`,
      providesTags: ['MyProfile'],
    }),
    getMyProfile: builder.query<UserProfile | null, void>({
      query: () => `/auth/getUserProfile`,
      providesTags: ['MyProfile', 'AuthProfile'],
    }),
    getMyAccount: builder.query<UserAccount | null, void>({
      query: () => `/auth/getUserAccount`,
      providesTags: ['MyProfile', 'AuthProfile'],
    }),
    getIdeasLegacy: builder.query<any, void>({
      query: () => `/legacy/ideaGenerator/ideas`,
    }),
    createIdeasLegacy: builder.mutation<any, { ideas: string[] }>({
      query: ({ ideas }) => ({
        url: `/legacy/ideaGenerator/ideas`,
        method: 'POST',
        body: { ideas },
      }),
    }),
    deleteIdeaLegacy: builder.mutation<any, { idea: string }>({
      query: ({ idea }) => ({
        url: `/legacy/ideaGenerator/ideas`,
        method: 'DELETE',
        body: { idea },
      }),
    }),

    getRandomIdeaLegacy: builder.query<any, void>({
      query: () => `/legacy/ideaGenerator/randomIdeas`,
    }),

    getLegacyRelativeAccounts: builder.query<any, void>({
      query: () => `/legacy/relativeAccounts`,
      providesTags: ['MyProfile'],
    }),

    legacyChildProfiles: builder.query<ChildProfile[], void>({
      query: () => `/legacy/childProfiles`,
      providesTags: ['MyProfile'],
    }),

    reviewRecord: builder.mutation<void, ReviewRecord>({
      query: ({ id, ...body }) => ({
        url: `/records/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Submission', 'Ambassador'],
    }),

    getQuizzes: builder.query<Quiz[], void>({
      query: () => `/quizzes`,
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Quiz' as const, id })), 'Quiz']
          : ['Quiz'],
    }),
    getQuizById: builder.query<Quiz, string>({
      query: (id: string) => `/quizzes/${id}`,
      providesTags: ['Quiz'],
    }),
    createAttempt: builder.mutation<QuizAttempt, CreateAttemptProps>({
      query: (body) => ({
        url: `/quizzes/attempt`,
        method: 'POST',
        body,
      }),
      transformResponse: (attempt: QuizAttempt) => {
        const shuffledQuestions = attempt.questions.sort(
          () => Math.random() - 0.5,
        )
        return { ...attempt, questions: shuffledQuestions }
      },
      invalidatesTags: ['QuizAttempt'],
    }),
    restartAttempt: builder.mutation<QuizAttempt, string>({
      query: (id: string) => ({
        url: `/quizzes/attempt/${id}/restart`,
        method: 'POST',
      }),
      invalidatesTags: ['QuizAttempt'],
    }),
    completeAttempt: builder.mutation<string, CompleteBody>({
      query: ({ id, ...body }) => ({
        url: `/quizzes/attempt/${id}/complete`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['QuizAttempt', 'RecordHolders'],
    }),
    answerQuestion: builder.mutation<AnswerResult, AnswerBody>({
      query: ({ attemptId, ...body }) => ({
        url: `/quizzes/attempt/${attemptId}`,
        method: 'PATCH',
        body,
      }),
    }),
    suggestQuiz: builder.mutation<void, QuizSuggestion>({
      query: (body) => ({
        url: '/quiz/suggest',
        method: 'POST',
        body,
      }),
    }),
    getRecordHoldersInfo: builder.query<RecordHolders, string>({
      query: (id: string) => `/quizzes/${id}/recordHolders`,
      providesTags: ['RecordHolders'],
    }),
    getSubmissions: builder.query<SubmissionResponse, SubmissionFilters>({
      query: (params: SubmissionFilters) => {
        return {
          url: `/admin/submissions?${qs.stringify(params)}`,
        }
      },
      providesTags: ['Submission'],
    }),
    getAmbassadorsProgress: builder.query<AmbassadorsResponse[], void>({
      query: () => {
        return {
          url: `/admin/ambsassadorProgress`,
        }
      },
      providesTags: ['Ambassador'],
    }),
    updateAmbassadorFulfill: builder.mutation<void, AmbassadorFulfill>({
      query: (body) => ({
        url: `/admin/ambsassadorProgress`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Ambassador'],
    }),
    getMediaUploadLink: builder.query({
      query: (format: string) => `media/uploadUrl?format=${format}`,
    }),
    getSubmissionById: builder.query<Submission, string>({
      query: (id: string) => `/admin/submissions/${id}`,
      providesTags: ['Submission'],
    }),
    updateSubmission: builder.mutation<
      void,
      SubmissionUpdateBody & { id: string }
    >({
      query: ({ id, ...body }) => ({
        url: `/admin/submissions/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Submission'],
    }),
    updateRecordPriority: builder.mutation<void, RecordPriority>({
      query: ({ recordId, ...body }) => ({
        url: `/admin/record/${recordId}/priority`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Submission'],
    }),
    markAsBrokenRecord: builder.mutation<void, string>({
      query: (recordId: string) => ({
        url: `/admin/records/${recordId}/break`,
        method: 'PUT',
      }),
      invalidatesTags: ['Submission'],
    }),
    sendResetPinEmail: builder.mutation<void, void>({
      query: () => ({
        url: '/auth/sendResetPinLink',
        method: 'POST',
      }),
    }),
    updatePin: builder.mutation<void, ResetPinBody>({
      query: (body) => ({
        url: `/auth/updatePin`,
        method: 'PATCH',
        body,
      }),
    }),
    verifyPin: builder.mutation<void, { pin: string }>({
      query: (body) => ({
        url: `/auth/verifyPin`,
        method: 'POST',
        body,
      }),
    }),
    verifySignUpEmail: builder.mutation<void, { email: string }>({
      query: (body) => ({
        url: `/auth/verifyEmail`,
        method: 'POST',
        body,
      }),
    }),

    getChildSubmissions: builder.query<ChildSubmissionResponse, string>({
      query: (id: string) => `/child/${id}/submissions`,
    }),
    getChildRecordById: builder.query<
      Submission & { linkApplePass: string },
      string
    >({
      query: (id: string) => `/child/records/${id}`,
      providesTags: ['Submission'],
    }),

    likeRecord: builder.mutation<
      { totalLikes: number; isLiked: boolean },
      string
    >({
      query: (id: string) => ({
        url: `/records/${id}/like`,
        method: 'PATCH',
      }),
    }),

    updateChild: builder.mutation<void, ChildUpdateBody & { id: string }>({
      query: ({ id, ...body }) => ({
        url: `/child/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Child'],
    }),
    deleteChild: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `/child/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Child', 'MyProfile', 'Submission', 'Record'],
    }),
    sendContactUsEmail: builder.mutation<void, ContactUsBody>({
      query: (body) => ({
        url: `/contactUs`,
        method: 'POST',
        body,
      }),
    }),
    subscribeToNewsletter: builder.mutation<void, { email: string }>({
      query: (body) => ({
        url: `/newsletterSignup`,
        method: 'POST',
        body,
      }),
    }),

    editParentPin: builder.mutation<void, { pin: string }>({
      query: (body) => ({
        url: `/parents/updatePin`,
        method: 'PATCH',
        body,
      }),
    }),
    sendMagicLink: builder.mutation<void, string>({
      query: (email) => ({
        url: `/auth/sendMagicLink`,
        method: 'POST',
        body: { email },
      }),
    }),
    login: builder.mutation<void, string>({
      query: (token) => ({
        url: `/auth/login?token=${token}`,
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }): Promise<void> {
        const response = (await queryFulfilled) as unknown as any
        localStorage.set('AUTH_TOKEN', response.data.authToken)
        dispatch(kwrApi.util.invalidateTags(['MyProfile', 'AuthProfile']))
      },
    }),
    loginToAccount: builder.mutation<
      { pin: string; authToken: string },
      string
    >({
      query: (email) => ({
        url: `/auth/loginToAccount?${qs.stringify({ email: email })}`,
      }),
    }),
    switchProfile: builder.mutation({
      query: (accountId: string) => ({
        url: `/auth/switchProfile?accountId=${accountId}`,
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }): Promise<void> {
        const response = (await queryFulfilled) as unknown as any
        localStorage.set('AUTH_TOKEN', response.data.authToken)
        dispatch(kwrApi.util.invalidateTags(['MyProfile', 'AuthProfile']))
      },
    }),

    updateParentProfile: builder.mutation<
      void,
      ParentUpdateBody & { id: string }
    >({
      query: ({ id, ...body }) => ({
        url: `/parents/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['MyProfile'],
    }),

    createRecordSubmission: builder.mutation<Record, SubmissionCreateBody>({
      query: (body) => ({
        url: `/legacy/submission/create`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Submission'],
    }),
    updateRecordSubmission: builder.mutation<
      void,
      GroupRecordUpdateBody & { id: string }
    >({
      query: ({ id, ...body }) => ({
        url: `/legacy/submissions/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Submission'],
    }),

    getIPLocation: builder.query<IPLocationResponse, void>({
      query: () => `https://ipapi.co/json/`,
    }),
    getLocationsForRecords: builder.query<Location[], void>({
      query: () => `/locations`,
    }),
    getEventParticipantInfo: builder.query<EventParticipant[], string>({
      query: (body) => `/events/participant/certificate?token=${body}`,
    }),
    downloadEventParticipantCertificate: builder.query<
      string,
      { participantId: string; token: string }
    >({
      query: ({ participantId, token }) =>
        `/events/participant/${participantId}/certificate/download?token=${token}`,
    }),
    getEventParticipantCertificate: builder.query<
      string,
      { participantId: string; eventId: string }
    >({
      query: (params) =>
        `/admin/events/participants/certificate?${qs.stringify(params)}`,
    }),
    getScoreboardDataByQuizId: builder.query<QuizRecordScore, string>({
      query: (quizId: string) => `/quizzes/scoreboard/${quizId}`,
    }),

    getProductById: builder.query<Product, string>({
      // @ts-ignore
      queryFn: async (id: string) => {
        try {
          const product = await shopifyClient.product.fetch(id)
          return { data: transformProductData(product) }
        } catch (error) {
          return { error }
        }
      },
    }),
    getProducts: builder.query<Product[], void>({
      // @ts-ignore
      queryFn: async () => {
        try {
          const products = await shopifyClient.product.fetchAll()
          return {
            data: products.map((product) => transformProductData(product)),
          }
        } catch (error) {
          return { error }
        }
      },
    }),
    getChildrenRecords: builder.query<ChildRecords[], void>({
      query: () => `/children/records`,
      providesTags: ['MyProfile'],
    }),
    getRecordCertificate: builder.query<string, string>({
      query: (id: string) => `/child/records/${id}/certificate`,
    }),
    getQuizRecordsForScoreBoard: builder.query<QuizRecord[], void>({
      query: () => `/quizzes/scoreboard/data/records`,
    }),

    getRecommendationById: builder.query<Recommendation, string>({
      query: (id: string) => `/records/${id}/recommendation`,
      providesTags: ['Recommendation'],
    }),
    createRecommendation: builder.mutation<
      Recommendation,
      RecommendationUpdateBody & { recordId: string }
    >({
      query: ({ recordId, ...body }) => ({
        url: `/admin/records/${recordId}/recommendation`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Recommendation'],
    }),
    updateRecommendation: builder.mutation<
      Recommendation,
      RecommendationUpdateBody & { id: string }
    >({
      query: ({ id, ...body }) => ({
        url: `/admin/records/recommendation/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Recommendation'],
    }),
    deleteRecommendation: builder.mutation<void, string>({
      query: (recordId: string) => ({
        url: `/admin/records/${recordId}/recommendation`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Recommendation'],
    }),
    getWorldMapData: builder.query<WorldMapRecordsAmount, void>({
      query: () => `/world-map`,
      providesTags: ['WorldMapData'],
    }),
    getCmsRecordByContentName: builder.query<CmsData, string>({
      query: (contentName: string) => `/cms/${contentName}`,
      providesTags: ['CmsData'],
    }),

    updateCmsTableRecord: builder.mutation<CmsData, CmsDataToUpdate>({
      query: (body) => ({
        url: `/cms`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['CmsData'],
    }),

    getFilteredRecords: builder.query<string, ExportRecordsFormProps>({
      query: (params: ExportRecordsFormProps) => {
        return {
          url: `/admin/filteredRecords?${qs.stringify(params)}`,
          responseHandler: (response: any) => {
            if (!response.ok) {
              return { error: response.statusText, status: response.status }
            }
            return response.blob()
          },
        }
      },
    }),
    getEventsParticipantsFile: builder.query<string, string>({
      query: (eventId: string) => {
        return {
          url: `/admin/eventsParticipantsFile?eventId=${eventId}`,
          responseHandler: (response: any) => {
            if (!response.ok) {
              return { error: response.statusText, status: response.status }
            }
            return response.blob()
          },
        }
      },
    }),

    getGroupRecordsForParent: builder.query<ChildSubmissionResponse, string>({
      query: (id: string) => `/parent/${id}/group-records`,
    }),
    getPaymentForAllCountries: builder.query<PaymentForCountry[], void>({
      query: () => `/admin/paymentForCountries`,
      providesTags: ['PaymentForCountry'],
    }),
    createPaymentForCountry: builder.mutation<
      PaymentForCountry,
      PaymentForCountry
    >({
      query: (body) => ({
        url: `/admin/paymentForCountry`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PaymentForCountry'],
    }),
    updatePaymentForCountry: builder.mutation<
      PaymentForCountry,
      PaymentForCountry
    >({
      query: (body) => ({
        url: `/admin/paymentForCertainCountry`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['PaymentForCountry'],
    }),
    deletePaymentForCountry: builder.mutation<string, string>({
      query: (id: string) => ({
        url: `/admin/paymentForCountry/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PaymentForCountry'],
    }),

    getAllUsers: builder.query<ParentResponse[], AllUsersFilters>({
      query: (params: AllUsersFilters) => {
        return {
          url: `/admin/allUsers?${qs.stringify(params)}`,
        }
      },
    }),
  }),
})

export const {
  useUpdateCmsTableRecordMutation,
  useGetCmsRecordByContentNameQuery,
  useCreateParentMutation,
  useCreateChildMutation,
  useApplyToEventMutation,
  useCreateEventMutation,
  useGetEventParticipantsQuery,
  useUpdateEventParticipantStatusMutation,
  useGetDraftQuery,
  useGetEventsNameListQuery,
  useGetEventsQuery,
  useGetPublicEventsQuery,
  useVerifySignUpEmailMutation,
  useGetMediaUploadLinkQuery,
  useGetEventForParticipationQuery,
  useLazyGetEventForParticipationQuery,
  useGetEventQuery,
  useUpdateHeadContentMutation,
  useUpdateEventMutation,
  useUpdateEventPrivacyMutation,
  useUpdateEventVisibilityMutation,
  useDeleteEventMutation,
  useGetHeadContentQuery,
  useSentCertificatesMutation,
  useGetChildQuery,
  useGetSponsorsQuery,
  useGetPublicSponsorsQuery,
  useGetAuthProfileQuery,
  useCreateSponsorMutation,
  useUpdateSponsorMutation,
  useDeleteSponsorMutation,
  useLetsTalkMutation,
  useGetRecordByIdQuery,
  useGetPublicRecordsQuery,
  useGetLegacyRelativeAccountsQuery,
  useLazyGetPublicRecordsQuery,
  useLazyGetRecordByIdQuery,
  useCreateRecordMutation,
  useJoinAmbassadorProgramMutation,
  useGetAmbassadoredRecordsQuery,
  useLazyGetAmbassadorQuery,
  useGetProfileQuery,
  useReviewRecordMutation,
  useGetQuizzesQuery,
  useGetQuizByIdQuery,
  useCreateAttemptMutation,
  useRestartAttemptMutation,
  useCompleteAttemptMutation,
  useAnswerQuestionMutation,
  useGetRecordHoldersInfoQuery,
  useSuggestQuizMutation,
  useLazyGetSubmissionsQuery,
  useLazyGetAmbassadorsProgressQuery,
  useGetAmbassadorsProgressQuery,
  useGetSubmissionByIdQuery,
  useUpdateSubmissionMutation,
  useUpdateRecordPriorityMutation,
  useMarkAsBrokenRecordMutation,
  useSendResetPinEmailMutation,
  useUpdatePinMutation,
  useVerifyPinMutation,
  useLegacyChildProfilesQuery,
  useLazyGetChildSubmissionsQuery,
  useLazyGetIdeasLegacyQuery,
  useCreateIdeasLegacyMutation,
  useDeleteIdeaLegacyMutation,
  useLazyGetRandomIdeaLegacyQuery,
  useGetChildRecordByIdQuery,
  useLikeRecordMutation,
  useUpdateChildMutation,
  useDeleteChildMutation,
  useSendMagicLinkMutation,
  useSendContactUsEmailMutation,
  useSubscribeToNewsletterMutation,
  useLoginMutation,
  useSwitchProfileMutation,
  useLoginToAccountMutation,
  useUpdateParentProfileMutation,
  useEditParentPinMutation,
  useCreateRecordSubmissionMutation,
  useUpdateRecordSubmissionMutation,
  useGetIPLocationQuery,
  useGetLocationsForRecordsQuery,
  useGetEventParticipantInfoQuery,
  useDownloadEventParticipantCertificateQuery,
  useLazyGetEventParticipantCertificateQuery,
  useGetScoreboardDataByQuizIdQuery,
  useGetProductByIdQuery,
  useGetProductsQuery,
  useGetChildrenRecordsQuery,
  useLazyGetRecordCertificateQuery,
  useGetQuizRecordsForScoreBoardQuery,
  useGetWorldMapDataQuery,
  useGetRecommendationByIdQuery,
  useCreateRecommendationMutation,
  useUpdateRecommendationMutation,
  useDeleteRecommendationMutation,
  useLazyGetFilteredRecordsQuery,
  useLazyGetGroupRecordsForParentQuery,
  useUpdateAmbassadorFulfillMutation,
  useLazyGetPaymentForAllCountriesQuery,
  useGetPaymentForAllCountriesQuery,
  useCreatePaymentForCountryMutation,
  useUpdatePaymentForCountryMutation,
  useDeletePaymentForCountryMutation,
  useLazyGetAllUsersQuery,
  useLazyGetEventsParticipantsFileQuery,
  useGetMyProfileQuery,
  useGetMyAccountQuery,
} = kwrApi
